const data = {
    id : {
        home    : "Rumah",
        about   : "Tentang",
        service : "Layanan",
        client  : "Klien",
        award   : "Penghargaan",
        pages   : "Halaman",
        project : "Proyek",
        product : "Produk",
        team    : "Tim",
        blog    : "Blog",
        contact : "Kontak"
    },
    en : {
        home    : "Home",
        about   : "About",
        service : "Service",
        client  : "Client",
        award   : "Award",
        pages   : "Pages",
        project : "Project",
        product : "Product",
        team    : "Team",
        blog    : "Blog",
        contact : "Contact"
    }
}

export default data