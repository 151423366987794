import React, {useContext, useEffect} from "react";
import {Button, Layout, Space} from "antd";
import {FacebookOutlined, InstagramOutlined, MenuOutlined, TwitterOutlined} from "@ant-design/icons"
import Menu from "../menu";
import {DrawerContext} from "contexts/drawer";
import ReactGA from 'react-ga';

const Header: React.FC = () => {

    const [, setVisible] = useContext(DrawerContext);

    useEffect(() => {

        let navbar = document.getElementById("navbar");

        window.onscroll = () => {
            if (window.pageYOffset > 100) {
                navbar?.classList.add("scrolled")
            } else {
                navbar?.classList.remove("scrolled")
            }
        }
    }, [])

    return (
        <Layout.Header id={"navbar"} className="public-header">
           <div className={"public-container"}>

               <div className={"logo"}>
                   <img width={100} src={"/logo.webp"} alt={"Logo"}/>
               </div>

               <div className={"social-media"}>
                   <Space size={"large"}>
                       <a
                           href={"https://www.facebook.com/wasdlabs"}
                           target={"_blank"}
                           rel={"noreferrer"}
                           onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "facebook_header" })}
                       >
                           <FacebookOutlined />
                       </a>
                       <a
                           href={"https://www.instagram.com/wasdlabs/"}
                           target={"_blank"}
                           rel={"noreferrer"}
                           onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "instagram_header" })}
                       >
                           <InstagramOutlined />
                       </a>
                       <a
                           href={"https://www.facebook.com/wasdlabs"}
                           target={"_blank"}
                           rel={"noreferrer"}
                           onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "twitter_header" })}
                       >
                           <TwitterOutlined />
                       </a>
                   </Space>
               </div>

               <div className={"button-menu"}>
                   <Button shape={"circle"} type={"primary"} onClick={() => setVisible(true)}>
                       <MenuOutlined />
                   </Button>
               </div>

               <div className={"navigation"}>
                   <Menu mode={"horizontal"} />
               </div>

           </div>
        </Layout.Header>
    )
}

export default React.memo(Header);