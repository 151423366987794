import React from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";
import data from "./data";

interface Props {
    mode : "vertical" | "horizontal" | "inline"
}

const Menus : React.FC<Props> = ({mode}) => {
    return (
        <Menu defaultSelectedKeys={['1']} mode={mode}>
            <Menu.Item key="1">
                <Link to={"/"}>{data.en.home}</Link>
            </Menu.Item>
            <Menu.Item key="2" >
                <a href={"/#about"}>{data.en.about}</a>
            </Menu.Item>
            <Menu.Item key="3" >
                <a href={"/#service"}>{data.en.service}</a>
            </Menu.Item>
            <Menu.Item key="4" >
                <a href={"/#client"}>{data.en.client}</a>
            </Menu.Item>
            <Menu.Item key="5" >
                <a href={"/#award"}>{data.en.award}</a>
            </Menu.Item>
            <Menu.SubMenu key="sub" title={data.en.pages} >
                <Menu.Item key="sub 1" style={{background : "transparent"}}>
                    <Link to={"/project"}>{data.en.project}</Link>
                </Menu.Item>
                <Menu.Item key="sub 4" style={{background : "transparent"}}>
                    <Link to={"/product"}>{data.en.product}</Link>
                </Menu.Item>
                <Menu.Item key="sub 2" style={{background : "transparent"}}>
                    <Link to={"/team"}>{data.en.team}</Link>
                </Menu.Item>
                <Menu.Item key="sub 3" style={{background : "transparent"}}>
                    <Link to={"/blog"}>{data.en.blog}</Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="7" >
                <a href={"/#contact"}>{data.en.contact}</a>
            </Menu.Item>
        </Menu>
    )
}

export default React.memo(Menus);