import React from "react";

interface Pages {
  path: string;
  isExact: boolean;
  component: React.LazyExoticComponent<React.FC>;
}

let pages: Pages[] = [
  {
    path: "/blog/:id/:title?",
    isExact: false,
    component: React.lazy(() => import("../pages/blogPost")),
  },
  {
    path: "/blog",
    isExact: true,
    component: React.lazy(() => import("../pages/blog")),
  },
  {
    path: "/team/:name",
    isExact: true,
    component: React.lazy(() => import("../pages/teamDetail")),
  },
  {
    path: "/team",
    isExact: true,
    component: React.lazy(() => import("../pages/team")),
  },
  {
    path: "/product",
    isExact: true,
    component: React.lazy(() => import("../pages/product")),
  },
  {
    path: "/project",
    isExact: true,
    component: React.lazy(() => import("../pages/project")),
  },
  {
    path: "/",
    isExact: true,
    component: React.lazy(() => import("../pages/home")),
  },
  {
    path: "**",
    isExact: true,
    component: React.lazy(() => import("../pages/404")),
  },
];

export default pages;
